export const allProjects = [
  {
    name: "Strong Habit",
    id: 1,
    to: "/strong-habit",
    thumbnail: "",
    language: "React",
    active: true,
    link: "/strong-habit",
    description: "Habit tracking application with daily progress monitoring",
  },
  {
    name: "Developer Job Board",
    id: 2,
    to: "/",
    thumbnail: "",
    language: "React",
    active: false,
    link: "/",
    description: "Platform for tech job listings and applications",
  },
  {
    name: "Get It Done (another todo-list)",
    id: 3,
    to: "",
    thumbnail: "",
    language: "JavaScript",
    active: false,
    link: "/",
    description: "Task management application with priority sorting",
  },
  {
    name: "Workout Logger",
    id: 4,
    to: "",
    thumbnail: "",
    language: "React",
    active: false,
    link: "/",
    description: "Exercise tracking tool for fitness enthusiasts",
  },
];
